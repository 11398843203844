import { defineMessages } from 'react-intl';

export default defineMessages({
  cart: {
    defaultMessage: 'Prescription glasses online from {basePrice}',
    id: 'pageTitles.cart'
  },
  checkout: {
    defaultMessage: 'Checkout',
    id: 'pageTitles.checkout'
  },
  collection: {
    defaultMessage: 'Collection',
    id: 'pageTitles.collection'
  },
  eyeTest: {
    defaultMessage: 'Eye Test Booking',
    id: 'pageTitles.eyeTest'
  },
  generic: {
    defaultMessage: 'Prescription glasses online from {basePrice}',
    id: 'pageTitles.generic'
  },
  home: {
    defaultMessage: 'Home',
    id: 'pageTitles.home'
  },
  notFoundPage: {
    defaultMessage: '404',
    id: 'pageTitles.NotFoundPage'
  },
  pdPage: {
    defaultMessage: 'Add your pupillary distance',
    id: 'pageTitles.pdPage'
  },
  profile: {
    defaultMessage: 'Profile',
    id: 'pageTitles.profile'
  },
  search: {
    defaultMessage: 'Search Products',
    id: 'pageTitles.search'
  },
  statusPdPage: {
    defaultMessage: 'status of your purchase',
    id: 'pageTitles.pdPageStatus'
  },
  successDonationPage: {
    defaultMessage: 'Donation Successful',
    id: 'pageTitles.successDonationPage'
  },
  successNewsletterPage: {
    defaultMessage: 'subscription successful',
    id: 'pageTitles.successNewsletterPage'
  },
  successPdPage: {
    defaultMessage: 'successfully updated your pupillary distance',
    id: 'pageTitles.pdPageSuccess'
  },
  unsubscribe: {
    defaultMessage: 'Unsubscribe',
    id: 'pageTitles.unsubscribe'
  },
  updatePrescription: {
    defaultMessage: 'Update Prescription',
    id: 'pageTitles.updatePrescription'
  },
  updatePrescriptionSuccess: {
    defaultMessage: 'Prescription Updated',
    id: 'pageTitles.updatePrescriptionSuccess'
  },
  wishlist: {
    defaultMessage: 'Wishlist',
    id: 'pageTitles.wishlist'
  }
});
