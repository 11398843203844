import React from 'react';
import { brandColors, Typography } from '@aceandtate/ds';
import { FormattedMessage } from 'react-intl';
import priceDisplayMessages from 'messages/priceDisplay';

type Props = {
  fromPrice: string;
  salePrice?: string;
  includingPrescription?: boolean;
  priceOnly?: boolean;
  isAccent?: boolean;
};

export default function ProductPrice(props: Props): JSX.Element {
  const { fromPrice, salePrice, includingPrescription = true, priceOnly = false, isAccent } = props;

  const priceColor = salePrice ? brandColors.sale : isAccent ? brandColors.accent : brandColors.dark;

  const message = includingPrescription ? (
    <FormattedMessage {...priceDisplayMessages.including_prescription} />
  ) : (
    <FormattedMessage {...priceDisplayMessages.non_prescription} />
  );

  return (
    <>
      {priceOnly ? (
        <>
          {salePrice && (
            <>
              <Typography style={{ textDecoration: 'line-through' }} as='span'>
                {fromPrice}
              </Typography>
              &nbsp;
            </>
          )}

          <Typography variant='h6' as='span' color={priceColor}>
            {salePrice || fromPrice}
          </Typography>
        </>
      ) : (
        <Typography variant='bodyM'>
          <FormattedMessage
            {...priceDisplayMessages.price}
            values={{
              price: (
                <>
                  {salePrice && (
                    <>
                      <Typography style={{ textDecoration: 'line-through' }} as='span'>
                        {fromPrice}
                      </Typography>
                      &nbsp;
                    </>
                  )}

                  <Typography variant='h6' as='span' color={priceColor}>
                    {salePrice || fromPrice}&nbsp;
                  </Typography>
                </>
              )
            }}
          />
          {message}
        </Typography>
      )}
    </>
  );
}
